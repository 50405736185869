import React from "react"
import "./heroVideo.css"
import ReactPlayer from "react-player"

function HeroVideo({ setVidReady }) {
  function renderMobile() {
    return (
      <>
        <iframe
          src="https://player.vimeo.com/video/617411021?h=d95e97e136&autoplay=1&loop=1&autopause=0&muted=1&background=1"
          width="600"
          height="800"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title="mobile"
          allowfullscreen
        />
        <script src="https://player.vimeo.com/api/player.js"></script>
      </>
    )
  }
  return (
    <>
      <div class="player-wrapper desktop-video-container">
        <ReactPlayer
          onStart={() => {
            console.log("start")
            setVidReady(true)
          }}
          className="react-player"
          url="https://player.vimeo.com/video/604412480?background=1"
          width="100%"
          height="100%"
          playing
          loop
          volume
          muted
        />
      </div>
      <div>
        <div class="mobile-video-container">{renderMobile()}</div>
      </div>
    </>
  )
}

export default HeroVideo
