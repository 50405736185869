import React from "react"
import "./brandSlideshow.css"

function BrandSlideshow() {
  return (
    <div class="wrapper">
      <div
        data-sal="fade"
        data-sal-duration="600"
        data-sal-delay="50"
        class="slider"
      >
        <div class="slide">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/NewYorkTimes.svg/1200px-NewYorkTimes.svg.png"
            alt="New York Times"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/f/f8/VOGUE_LOGO.svg"
            alt="vogue"
          />
          <img
            src="https://www.nicepng.com/png/full/94-943458_2000px-billboard-logo-svg-billboard-magazine-logo-transparent.png"
            alt="billboard"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Gucci_Logo.svg/622px-Gucci_Logo.svg.png?20180607152741"
            alt="Gucci"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Time_Magazine_logo.svg/596px-Time_Magazine_logo.svg.png"
            alt="Time Magazine"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/H%26M-Logo.svg/2560px-H%26M-Logo.svg.png"
            alt="H&M"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Tidal_%28service%29_logo.svg/1280px-Tidal_%28service%29_logo.svg.png"
            alt="Tidal"
          />
          <img
            src="https://www.nicepng.com/png/full/292-2923415_parkwood-entertainment-logo.png"
            alt="Parkwood"
          />
        </div>
        <div class="slide">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/NewYorkTimes.svg/1200px-NewYorkTimes.svg.png"
            alt="New York Times"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/f/f8/VOGUE_LOGO.svg"
            alt="vogue"
          />
          <img
            src="https://www.nicepng.com/png/full/94-943458_2000px-billboard-logo-svg-billboard-magazine-logo-transparent.png"
            alt="billboard"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Gucci_Logo.svg/622px-Gucci_Logo.svg.png?20180607152741"
            alt="Gucci"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Time_Magazine_logo.svg/596px-Time_Magazine_logo.svg.png"
            alt="Time Magazine"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/H%26M-Logo.svg/2560px-H%26M-Logo.svg.png"
            alt="H&M"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Tidal_%28service%29_logo.svg/1280px-Tidal_%28service%29_logo.svg.png"
            alt="Tidal"
          />
          <img
            src="https://www.nicepng.com/png/full/292-2923415_parkwood-entertainment-logo.png"
            alt="Parkwood"
          />
        </div>
        <div class="slide">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/NewYorkTimes.svg/1200px-NewYorkTimes.svg.png"
            alt="New York Times"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/f/f8/VOGUE_LOGO.svg"
            alt="vogue"
          />
          <img
            src="https://www.nicepng.com/png/full/94-943458_2000px-billboard-logo-svg-billboard-magazine-logo-transparent.png"
            alt="billboard"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Gucci_Logo.svg/622px-Gucci_Logo.svg.png?20180607152741"
            alt="Gucci"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Time_Magazine_logo.svg/596px-Time_Magazine_logo.svg.png"
            alt="Time Magazine"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/H%26M-Logo.svg/2560px-H%26M-Logo.svg.png"
            alt="H&M"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Tidal_%28service%29_logo.svg/1280px-Tidal_%28service%29_logo.svg.png"
            alt="Tidal"
          />
          <img
            src="https://www.nicepng.com/png/full/292-2923415_parkwood-entertainment-logo.png"
            alt="Parkwood"
          />
        </div>
        <div class="slide">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/NewYorkTimes.svg/1200px-NewYorkTimes.svg.png"
            alt="New York Times"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/f/f8/VOGUE_LOGO.svg"
            alt="vogue"
          />
          <img
            src="https://www.nicepng.com/png/full/94-943458_2000px-billboard-logo-svg-billboard-magazine-logo-transparent.png"
            alt="billboard"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Gucci_Logo.svg/622px-Gucci_Logo.svg.png?20180607152741"
            alt="Gucci"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Time_Magazine_logo.svg/596px-Time_Magazine_logo.svg.png"
            alt="Time Magazine"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/H%26M-Logo.svg/2560px-H%26M-Logo.svg.png"
            alt="H&M"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Tidal_%28service%29_logo.svg/1280px-Tidal_%28service%29_logo.svg.png"
            alt="Tidal"
          />
          <img
            src="https://www.nicepng.com/png/full/292-2923415_parkwood-entertainment-logo.png"
            alt="Parkwood"
          />
        </div>
        <div class="slide">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/NewYorkTimes.svg/1200px-NewYorkTimes.svg.png"
            alt="New York Times"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/f/f8/VOGUE_LOGO.svg"
            alt="vogue"
          />
          <img
            src="https://www.nicepng.com/png/full/94-943458_2000px-billboard-logo-svg-billboard-magazine-logo-transparent.png"
            alt="billboard"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Gucci_Logo.svg/622px-Gucci_Logo.svg.png?20180607152741"
            alt="Gucci"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Time_Magazine_logo.svg/596px-Time_Magazine_logo.svg.png"
            alt="Time Magazine"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/H%26M-Logo.svg/2560px-H%26M-Logo.svg.png"
            alt="H&M"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Tidal_%28service%29_logo.svg/1280px-Tidal_%28service%29_logo.svg.png"
            alt="Tidal"
          />
          <img
            src="https://www.nicepng.com/png/full/292-2923415_parkwood-entertainment-logo.png"
            alt="Parkwood"
          />
        </div>
      </div>
    </div>
  )
}

export default BrandSlideshow
