import React, { useState } from "react"
import Loader from "../components/loader/loader"

import "./index.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroVideo from "../components/heroVid/heroVideo"
import BrandSlideshow from "../components/brandSlideshow/brandSlideshow"

function IndexPage() {
  const [vidReady, setVidReady] = useState(false)
  const [burgerIsOpen, setBurgerIsOpen] = useState(false)

  return (
    <>
      <Layout
        setVidReady={setVidReady}
        burgerIsOpen={burgerIsOpen}
        setBurgerIsOpen={setBurgerIsOpen}
      >
        {!vidReady && <Loader />}
        <Seo title="Falconi Media" />
        <HeroVideo setVidReady={setVidReady} />
        <BrandSlideshow />
      </Layout>
    </>
  )
}

export default IndexPage
