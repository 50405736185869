import React from "react"
import "./loader.css"

function Loader() {
  return (
    <div className="loader-container">
      <div className="loader">FM</div>
    </div>
  )
}

export default Loader
